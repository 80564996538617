.headerContainer {
  position:relative;
  line-height: 1.2;
  min-height: 100vh;
  background:url(/images/header.jpg) no-repeat center top;
  background-size:100% 100%;
  color:#fff;
}
.headerBgFiller{
    display:none;
}
.bgContainer{
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
}
.list{
  padding-left:1em;
  list-style:none;
}
.list > li {
  margin:15px 0;
}
@media (max-width: 31em) {
  .headerBgFiller{
    display:block;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:51vh;
    background-color:#023bff;
    z-index:-1;
  }
  .headerContainer {
    background-position:center 45vh;
  }
  .list{
    padding-left:.5em;
  }
  .list > li {
    margin:10px 0;
  }
  .textContainer{
    word-break:break-word;
  }
}