.defaultOpacity{
  opacity:1;
  position: relative;
  transition:all 0.15s ease-in-out !important;
}
.hidden{
  opacity:0;
}
.clickable{
  cursor:pointer;
}
/*
.active,
.interactive:hover{
  background-color:color(cardBgHover) !important;
  box-shadow:0px 0px 0px 1px rgba(0,54,255,0.3) !important;
}
*/
/*
.defaultOpacity.IDLE{
  z-index:1;
}
.IDLE:before{
  content:"";
  top:-0.5%;
  left:-0.5%;
  z-index: -2;
  width: 101%;
  height: 101%;
  filter: blur(4px);
  position: absolute;
  border-radius: 8px;
  background: linear-gradient(45deg, #0b50b0 0%, #2d7ded 20%, #669ff1 40%, #2d7ded 60%, #0b50b0 80%, #2d7ded 100%)
}
.IDLE:after{
  content:"";
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  border-radius: 8px;
}
*/
.defaultOpacity.rainbow{
  z-index:1;
}
.rainbow:before{
  content:"";
  top:-0.5%;
  left:-0.5%;
  z-index: -2;
  width: 101%;
  height: 101%;
  filter: blur(4px);
  position: absolute;
  border-radius: 8px;
  background: linear-gradient(45deg, rgb(255, 0, 0) 0%, rgb(255, 154, 0) 10%, rgb(208, 222, 33) 20%, rgb(79, 220, 74) 30%, rgb(63, 218, 216) 40%, rgb(47, 201, 226) 50%, rgb(28, 127, 238) 60%, rgb(95, 21, 242) 70%, rgb(186, 12, 248) 80%, rgb(251, 7, 217) 90%, rgb(255, 0, 0) 100%);
}
.rainbow:after{
  top:0;
  left:0;
  content:"";
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  border-radius: 8px;
}
.disabled:before{
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 8px;
  background: rgba(241, 238, 238, 0.6);
}