@keyframes dot-keyframes {
  0% {
    opacity: .4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: .4;
    transform: scale(1, 1);
  }
}

.loading-dots {
  text-align: center;
  width: 100%;

  &--dot {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    display: inline-block;
    box-sizing: border-box;
    background-color: #000000;
    animation: dot-keyframes 1.5s infinite ease-in-out;

    &:nth-child(2) {
      animation-delay: .5s;
    }

    &:nth-child(3) {
      animation-delay: 1s;
    }
  }
}
