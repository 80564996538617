.searchInput{
  width:100%;
  height:100%;
  position:absolute;
  border:0!important;
  outline:none!important;
  text-transform:uppercase;
  box-shadow:none!important;
}
.genericSelector:hover > .searchInput,
.searchInput:hover{
  background-color:#f7f8ff;
}
.searchInput.dark:hover{
  background-color:#13273f;
}
.searchInputHidden{
  opacity:0!important;
}