@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url(https://rsms.me/inter/inter.css);
body {
  margin: 0 auto;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', sans-serif; }

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif; } }

.pointer {
  cursor: pointer; }

.DashboardCard_defaultOpacity__3Ly5d {
  opacity: 1;
  position: relative;
  -webkit-transition: all 0.15s ease-in-out !important;
  transition: all 0.15s ease-in-out !important; }

.DashboardCard_hidden__1_j_O {
  opacity: 0; }

.DashboardCard_clickable__3ibwM {
  cursor: pointer; }

/*
.active,
.interactive:hover{
  background-color:color(cardBgHover) !important;
  box-shadow:0px 0px 0px 1px rgba(0,54,255,0.3) !important;
}
*/
/*
.defaultOpacity.IDLE{
  z-index:1;
}
.IDLE:before{
  content:"";
  top:-0.5%;
  left:-0.5%;
  z-index: -2;
  width: 101%;
  height: 101%;
  filter: blur(4px);
  position: absolute;
  border-radius: 8px;
  background: linear-gradient(45deg, #0b50b0 0%, #2d7ded 20%, #669ff1 40%, #2d7ded 60%, #0b50b0 80%, #2d7ded 100%)
}
.IDLE:after{
  content:"";
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  border-radius: 8px;
}
*/
.DashboardCard_defaultOpacity__3Ly5d.DashboardCard_rainbow__25Ce5 {
  z-index: 1; }

.DashboardCard_rainbow__25Ce5:before {
  content: "";
  top: -0.5%;
  left: -0.5%;
  z-index: -2;
  width: 101%;
  height: 101%;
  -webkit-filter: blur(4px);
          filter: blur(4px);
  position: absolute;
  border-radius: 8px;
  background: -webkit-linear-gradient(45deg, red 0%, #ff9a00 10%, #d0de21 20%, #4fdc4a 30%, #3fdad8 40%, #2fc9e2 50%, #1c7fee 60%, #5f15f2 70%, #ba0cf8 80%, #fb07d9 90%, red 100%);
  background: linear-gradient(45deg, red 0%, #ff9a00 10%, #d0de21 20%, #4fdc4a 30%, #3fdad8 40%, #2fc9e2 50%, #1c7fee 60%, #5f15f2 70%, #ba0cf8 80%, #fb07d9 90%, red 100%); }

.DashboardCard_rainbow__25Ce5:after {
  top: 0;
  left: 0;
  content: "";
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  border-radius: 8px; }

.DashboardCard_disabled__g_nwH:before {
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 8px;
  background: rgba(241, 238, 238, 0.6); }

.TableCellHeader_tooltip__FSkvs:hover {
  color: #7f7f80; }

.GenericSelector_searchInput__E1F2u {
  width: 100%;
  height: 100%;
  position: absolute;
  border: 0 !important;
  outline: none !important;
  text-transform: uppercase;
  box-shadow: none !important; }

.GenericSelector_genericSelector__3epuw:hover > .GenericSelector_searchInput__E1F2u,
.GenericSelector_searchInput__E1F2u:hover {
  background-color: #f7f8ff; }

.GenericSelector_searchInput__E1F2u.GenericSelector_dark__3Fh8V:hover {
  background-color: #13273f; }

.GenericSelector_searchInputHidden__1c1_I {
  opacity: 0 !important; }

.ModalCard_bg__dKXq3:before {
  z-index: 0;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: contain; }

.ModalCard_bg__dKXq3 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute; }

.ModalCard_bg-bottom-right__32tBy {
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 55%;
  top: auto;
  top: initial;
  left: auto;
  left: initial;
  background: url(/images/ellipse-bottomright.svg) no-repeat 100px 200px;
  background-size: cover; }

.ModalCard_bg-noise__1pVkK {
  z-index: 9;
  background-repeat: repeat;
  background-image: url(/images/bg-noise.png); }

/*
@media (max-width: 31em) {
  .bg-bottom-right{
    display:none;
  }
}
*/

.TwitterShareButton_twitterIcon__XFIHh {
  display: block;
  width: 20px;
  height: 20px;
  background: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2072%2072%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h72v72H0z%22%2F%3E%3Cpath%20class%3D%22icon%22%20fill%3D%22%23fff%22%20d%3D%22M68.812%2015.14c-2.348%201.04-4.87%201.744-7.52%202.06%202.704-1.62%204.78-4.186%205.757-7.243-2.53%201.5-5.33%202.592-8.314%203.176C56.35%2010.59%2052.948%209%2049.182%209c-7.23%200-13.092%205.86-13.092%2013.093%200%201.026.118%202.02.338%202.98C25.543%2024.527%2015.9%2019.318%209.44%2011.396c-1.125%201.936-1.77%204.184-1.77%206.58%200%204.543%202.312%208.552%205.824%2010.9-2.146-.07-4.165-.658-5.93-1.64-.002.056-.002.11-.002.163%200%206.345%204.513%2011.638%2010.504%2012.84-1.1.298-2.256.457-3.45.457-.845%200-1.666-.078-2.464-.23%201.667%205.2%206.5%208.985%2012.23%209.09-4.482%203.51-10.13%205.605-16.26%205.605-1.055%200-2.096-.06-3.122-.184%205.794%203.717%2012.676%205.882%2020.067%205.882%2024.083%200%2037.25-19.95%2037.25-37.25%200-.565-.013-1.133-.038-1.693%202.558-1.847%204.778-4.15%206.532-6.774z%22%2F%3E%3C%2Fsvg%3E) center center no-repeat; }

.TransactionListFilters_radioBtn__3gfAx input[type='radio'] {
  outline: none !important; }

@media (max-width: 31em) {
  .TransactionListFilters_radioBtn__3gfAx input[type='radio'] {
    width: 1.2em;
    height: 1.2em; } }

.TrancheWelcome_trancheFlash__AZvaE {
  background-size: auto 65%;
  background-repeat: no-repeat;
  background-position: 78% 100%;
  background-image: url(/images/trancheFlashBg.svg); }
  .TrancheWelcome_trancheFlash__AZvaE.TrancheWelcome_mobile__8mpVx {
    background-size: auto 50%;
    background-position: 100% 100%; }

@-webkit-keyframes LoadingSpinner_dot-keyframes__1cH4G {
  0% {
    opacity: .4;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2); }
  100% {
    opacity: .4;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@keyframes LoadingSpinner_dot-keyframes__1cH4G {
  0% {
    opacity: .4;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2); }
  100% {
    opacity: .4;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

.LoadingSpinner_loading-dots__2H7IC {
  text-align: center;
  width: 100%; }
  .LoadingSpinner_loading-dots--dot__1TQOS {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    display: inline-block;
    box-sizing: border-box;
    background-color: #000000;
    -webkit-animation: LoadingSpinner_dot-keyframes__1cH4G 1.5s infinite ease-in-out;
            animation: LoadingSpinner_dot-keyframes__1cH4G 1.5s infinite ease-in-out; }
    .LoadingSpinner_loading-dots--dot__1TQOS:nth-child(2) {
      -webkit-animation-delay: .5s;
              animation-delay: .5s; }
    .LoadingSpinner_loading-dots--dot__1TQOS:nth-child(3) {
      -webkit-animation-delay: 1s;
              animation-delay: 1s; }

.Header_gradientButton__XwnoZ {
  border-radius: 48px !important;
  background: #00ddff !important;
  background: -webkit-gradient(left top, right top, color-stop(0%, #00ddff), color-stop(100%, #00a9fe)) !important;
  background: -webkit-linear-gradient(left, #00ddff 0%, #00a9fe 100%) !important;
  background: linear-gradient(to right, #00ddff 0%, #00a9fe 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ddff', endColorstr='#00a9fe', GradientType=1 ) !important; }

.Header_gradientButton__XwnoZ:before {
  display: none !important; }

.Header_gradientButton__XwnoZ.Header_empty__sl23U {
  background: #fff !important;
  border: 1px solid #093dff; }

.Header_select__3MUn8 {
  background: #fff; }

.Header_selectDark__2UhPg {
  color: #fff !important;
  background: #162e4d !important; }
  .Header_selectDark__2UhPg * {
    color: #fff !important;
    background: #162e4d !important; }

.Header_input__33yby {
  outline: none; }

.Header_input__33yby:valid {
  border-color: #28c081; }

.Header_input__33yby:invalid {
  border-color: #dc2c10; }


.Web3ConnectionButtons_button__22U7L {
  color: #3F3D4B !important;
  border-color: transparent !important; }
  .Web3ConnectionButtons_button__22U7L:hover {
    border-color: #ccc !important;
    background-color: #f3f2f2 !important; }

.Footer_footer__3sgMk {
  background-image: url(/images/footer.png) !important;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% 80%;
  height: 70vh;
  min-height: 460px; }

@media (max-width: 31em) {
  .Footer_footer__3sgMk {
    height: auto !important;
    background-position: center bottom !important;
    background-size: cover !important; } }

.Footer_link__1VFp9 {
  text-decoration: none; }

.Footer_link__1VFp9:hover {
  text-decoration: underline; }

.PageNotFound_headerContainer__2_KWY {
  position: relative;
  line-height: 1.2;
  min-height: 100vh;
  background: url(/images/header.jpg) no-repeat center top;
  background-size: 100% 100%;
  color: #fff; }

.PageNotFound_headerBgFiller__KcFts {
  display: none; }

.PageNotFound_bgContainer__31oWs {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.PageNotFound_list__lpaEt {
  padding-left: 1em;
  list-style: none; }

.PageNotFound_list__lpaEt > li {
  margin: 15px 0; }

@media (max-width: 31em) {
  .PageNotFound_headerBgFiller__KcFts {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 51vh;
    background-color: #023bff;
    z-index: -1; }
  .PageNotFound_headerContainer__2_KWY {
    background-position: center 45vh; }
  .PageNotFound_list__lpaEt {
    padding-left: .5em; }
  .PageNotFound_list__lpaEt > li {
    margin: 10px 0; }
  .PageNotFound_textContainer__IPqbe {
    word-break: break-word; } }

